import packageJson from '../../package.json';

export type AppConfig = {
  name: string;
  baseUrl: string;
  enableDevTools: boolean;
  devErrorMessages: boolean;
  googleAnalyticsId: string | null;
  sensorScanDuration: number;
  faroEndpoint: string | null;
  enableAllFeatures?: boolean;
  enableAllData?: boolean;
  unleash: {
    url: string;
    clientKey: string;
    appName: string;
    refreshInterval: number;
  };
  enableJumpExperiment?: boolean;
};

export type Environment = AppConfig & {
  version: string;
  graphqlBaseUrl: string;
  isDevelopment: boolean;
  isWebBuild: boolean;
};

export const isWebBuild = !('__TAURI_INTERNALS__' in window);

// We're assuming that _environment is already initialized at the point it's
// used
export var environment: Environment = null as any as Environment;

const getConfigWeb = async () => {
  return { ...(await import('../environments/config.json')) };
};

export const getNativeConfig = async (): Promise<AppConfig> => {
  if (isWebBuild) {
    return await getConfigWeb();
  }
  const { invoke } = await import('@tauri-apps/api/core');
  return await invoke('plugin:cfg|config');
};

export const initialize = async () => {
  console.log('Initializing environment');
  const config = await getNativeConfig();
  const overrideApiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  if (overrideApiBaseUrl) {
    console.warn(`Overriding API base URL with ${overrideApiBaseUrl}`);
    config.baseUrl = overrideApiBaseUrl;
  }
  environment = {
    ...config,
    isDevelopment: config.name === 'development',
    version: packageJson.version,
    graphqlBaseUrl: config.baseUrl + '/graphql',
    isWebBuild,
  };
  console.info(`[Environment]: ${JSON.stringify(environment, null, 2)}`);
};
